import { blueGrey, teal, green, lightBlue, red, blue } from '@mui/material/colors';
import { christmas } from 'src/utils/christmasTime'


const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: christmas() ? white : '#18673C',
    //dark: teal[900],
    main: christmas() ? '#C7444C' : '#ADE5BA',
    //light: teal[700],
  },
  secondary: {
    contrastText: white,
    main: christmas() ? "#00293C" : '#7A7A7A',
    //light: deepPurple[100],
  },
  success: {
    contrastText: white,
    dark: green[900],
    main: green[800],
    light: green[100],
  },
  info: {
    contrastText: white,
    dark: lightBlue[900],
    main: lightBlue[800],
    light: lightBlue[100],
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[800],
    light: red[100],
  },
  text: {
    primary: christmas() ? blueGrey[900] : '#7A7A7A',
    secondary: blueGrey[600],
    link: blue[600],
  },
  background: {
    default: blueGrey[50],
    paper: white,
  },
  icon: teal[600],
  divider: white[200],
  action: {
    disabledBackground: "", // don't set the disable background color
    disabled: "white", // set the disable foreground color
  }
};

export default palette;
